<template>
  <base-section
    class="secondary pa-4"
  >
    <v-responsive
      class="mx-auto"
    >
      <v-container fluid>
        <v-row class="justify-center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'

  export default {
    name: 'SectionContactFeatures',
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      cards () {
        return [
          {
            icon: 'mdi-city-variant',
            title: this.translations?.contactTypesMainTitle,
            text: this.translations?.contactTypesMainDescription,
            scrollId: 'placeMainCity',
          },
          {
            icon: 'mdi-account-tie',
            title: this.translations?.contactTypesSectionsTitle,
            text: this.translations?.contactTypesSectionsDescriptions,
            scrollId: 'sections',
          },
          {
            icon: 'mdi-factory',
            title: this.translations?.contactTypesDepartments,
            text: this.translations?.contactTypesDepartmentsDescriptions,
            scrollId: 'departments',
          },
        ]
      },
    },
  }
</script>
